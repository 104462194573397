import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import FileUpload from "./FileUpload";

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://asterio.io';

const DocumentItem = ({ doc, onDelete, onRename }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newFilename, setNewFilename] = useState(doc.filename);

  const formatSummary = (summary) => {
    if (summary.startsWith("Error")) {
      return summary;
    }
    const paragraphs = summary.split("\n").filter((p) => p.trim() !== "");
    return paragraphs.join("\n\n");
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      onDelete();
    }
  };

  const handleRename = () => {
    onRename(doc.id, newFilename);
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleRename();
    }
  };

  return (
    <li className="bg-white p-4 rounded shadow">
      <div className="flex justify-between items-center">
        {isEditing ? (
          <input
            type="text"
            value={newFilename}
            onChange={(e) => setNewFilename(e.target.value)}
            onKeyDown={handleKeyDown}
            className="border rounded px-2 py-1"
            autoFocus
          />
        ) : (
          <h3 className="font-semibold text-lg">{doc.filename}</h3>
        )}
        <div>
          {isEditing ? (
            <>
              <button
                onClick={handleRename}
                className="text-blue-500 hover:text-blue-700 mr-2"
              >
                Save
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className="text-gray-500 hover:text-gray-700 mr-2"
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => setIsEditing(true)}
                className="text-blue-500 hover:text-blue-700 mr-2"
              >
                Rename
              </button>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-blue-500 hover:text-blue-700 mr-2"
              >
                {isExpanded ? "Hide Summary" : "Show Summary"}
              </button>
              <button
                onClick={handleDelete}
                className="text-red-500 hover:text-red-700"
              >
                Delete
              </button>
            </>
          )}
        </div>
      </div>
      <p className="text-sm text-gray-500">
        Uploaded on: {new Date(doc.upload_date).toLocaleString()}
      </p>
      {isExpanded && (
        <div className="mt-4">
          <h4 className="font-medium mb-2">Summary:</h4>
          <div
            className={`text-sm mt-1 p-3 bg-gray-50 rounded ${
              doc.summary.startsWith("Error") ? "text-red-500" : ""
            }`}
          >
            <ReactMarkdown className="prose prose-sm max-w-none">
              {formatSummary(doc.summary)}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </li>
  );
};

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const fetchDocuments = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${API_BASE_URL}/api/documents`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDocuments(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setError("Error fetching documents. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const deleteDocument = async (docId) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(`${API_BASE_URL}/api/documents/${docId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchDocuments();
    } catch (error) {
      console.error("Error deleting document:", error);
      setError("Error deleting document. Please try again.");
    }
  };

  const renameDocument = async (docId, newFilename) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${API_BASE_URL}/api/documents/${docId}/rename`,
        { newFilename },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setDocuments((prevDocs) =>
        prevDocs.map((doc) =>
          doc.id === docId ? { ...doc, filename: newFilename } : doc,
        ),
      );
    } catch (error) {
      console.error("Error renaming document:", error);
      setError("Error renaming document. Please try again.");
    }
  };

  const handleUploadComplete = (uploadedDoc) => {
    setDocuments((prevDocs) => [
      {
        id: uploadedDoc.documentId,
        filename: uploadedDoc.filename,
        upload_date: uploadedDoc.upload_date,
        summary: uploadedDoc.summary,
      },
      ...prevDocs,
    ]);
    setUploadProgress(0);
    setUploadError(null);
  };

  const handleUploadProgress = (progress) => {
    setUploadProgress(progress);
  };

  const handleUploadError = (errorMessage) => {
    setUploadError(errorMessage);
    setUploadProgress(0);
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  if (loading) return <div>Loading documents...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">My Documents</h2>
      <FileUpload
        onUploadComplete={handleUploadComplete}
        onProgress={handleUploadProgress}
        onError={handleUploadError}
      />
      {uploadProgress > 0 && uploadProgress < 100 && (
        <div className="mt-2">
          <div className="w-full bg-gray-200 rounded-full h-2.5">
		<div
  		className={`bg-blue-600 h-2.5 rounded-full w-[${uploadProgress}%]`}
		></div>
          </div>
          <p className="text-sm text-gray-500 mt-1">
            Uploading and processing: {uploadProgress}%
          </p>
        </div>
      )}
      {uploadError && <div className="mt-2 text-red-500">{uploadError}</div>}
      <ul className="mt-4 space-y-4">
        {documents.map((doc) => (
          <DocumentItem
            key={doc.id}
            doc={doc}
            onDelete={() => deleteDocument(doc.id)}
            onRename={renameDocument}
          />
        ))}
      </ul>
    </div>
  );
};

export default Documents;
