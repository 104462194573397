import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://asterio.io';

const Profile = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [about, setAbout] = useState('');

  useEffect(() => {
    const fetchAbout = async () => {
      try {
	console.log('Fetching token...');
    	const token = await getAccessTokenSilently();
    	console.log('Token received:', token.substring(0, 10) + '...');
        const response = await fetch(`${API_BASE_URL}/api/get-about`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = await response.json();
        setAbout(data.about);
      } catch (error) {
        console.error('Error fetching about:', error);
      }
    };

    if (isAuthenticated) {
      fetchAbout();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const handleSaveAbout = async () => {
    try {
      console.log('Fetching token...');
      const token = await getAccessTokenSilently();
      console.log('Token received:', token.substring(0, 10) + '...');
      const response = await fetch(`${API_BASE_URL}/api/save-about`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ about })
      });
      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error('Error saving about:', error);
    }
  };

 if (isLoading) {
    return <div>Loading ...</div>;
  }
  
  return (
    <div className="max-w-2xl mx-auto mt-10">
      <h2 className="text-3xl font-bold mb-6">Profile</h2>
      <div className="bg-white shadow-md rounded-lg p-6">
        <img src={user.picture} alt={user.name} className="w-24 h-24 rounded-full mx-auto mb-4" />
        <h3 className="text-2xl font-semibold text-center mb-2">{user.name}</h3>
        <p className="text-gray-600 text-center mb-6">{user.email}</p>
        <div>
          <h4 className="text-xl font-semibold mb-2">About</h4>
          <textarea 
            value={about} 
            onChange={(e) => setAbout(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md mb-4"
            rows="4"
          />
          <button 
            onClick={handleSaveAbout}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
