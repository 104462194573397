import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Welcome = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-4xl font-bold mb-6">Welcome to IEP Assistant</h1>
      <p className="text-xl mb-8">Please log in to access the AI chat interface.</p>
      <button
        onClick={() => loginWithRedirect()}
        className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Log In
      </button>
    </div>
  );
};

export default Welcome;
