import { useAuth0 } from "@auth0/auth0-react";
import Sidebar from "./Sidebar";
import React, { useEffect } from "react";

const Layout = ({ children }) => {
  const { logout, user, error } = useAuth0();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (error) {
      console.error("Auth0 error:", error);
    }
  }, [error]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-6xl mx-auto h-[calc(100vh-4rem)] bg-white rounded-lg shadow-inner border border-gray-200 overflow-hidden flex">
        <Sidebar />
        <div className="flex-1 flex flex-col min-w-0">
          <header className="bg-white shadow-sm p-4 flex justify-between items-center">
            <h1 className="text-2xl font-semibold text-gray-800">
              IEP Assistant
            </h1>
            <div className="flex items-center">
              <span className="mr-4 text-gray-600">Welcome, {user.name}</span>
              <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Log Out
              </button>
            </div>
          </header>
          <main className="flex-1 overflow-y-auto p-6">
            <div className="h-full">{children}</div>
          </main>
        </div>
      </div>
      <footer className="bg-gray-50 border-t border-gray-200 py-2 px-4 text-center text-sm text-gray-600">
        © {currentYear} 21GlassesLLC. All rights reserved.
      </footer>
    </div>
  );
};

export default Layout;
