import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import ChatInterface from './components/ChatInterface';
import Profile from './components/Profile'; // You'll need to create this
import { useAuth0 } from '@auth0/auth0-react';
import Welcome from './components/Welcome';
import History from './components/History';
import { ChatProvider } from './ChatContext';
import Documents from './components/Documents';

function App() {
  const { isAuthenticated, isLoading, error } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
   <ChatProvider>
    <Router>
      {isAuthenticated ? (
        <Layout>
          <Routes>
            <Route path="/" element={<ChatInterface />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/history" element={<History />} />
            <Route path="/documents" element={<Documents />} />
          </Routes>
        </Layout>
      ) : (
        <Welcome />
      )}
    </Router>
   </ChatProvider> 
  );
}

export default App;
